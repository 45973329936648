import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import IndividualCaseStudy from "../../screens/resources/case-study/slideshow"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <IndividualCaseStudy
        title="Case Study #2: Suzanne"
        src="https://media.visbymedical.com/case-study/sti-female-patient-suzanne/story.html"
        bodytxt="A case of undertreatment associated with syndromic management of STIs."
      />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
        title="Patient Case Study 2: Undertreatment of STIs"
        description="Suzanne, a 24-year-old living in a large city, presents to a nearby clinic with recent onset urinary frequency and urgency, and vaginal discharge and irritation."
        image="/meta/case-study-suzanne.jpg"
  />
)
